import React, {useEffect, useState} from 'react';
import SectionLayout from '../../CommonComponents/Layout/sectionLayout';

import {
  Table,
  Button,
  Form,
  Tag,
  notification,
  Skeleton,
  Row,
  Col,
  message,
  Modal,
} from 'antd';

import EditableCell from '../../CommonComponents/EditableCell';
import './style.css';
import ConfirmationAction from '../../CommonComponents/PaymentConfirmationAction';
import {useNavigate, useParams} from 'react-router-dom';

import CommonTable from '../../CommonComponents/EventCommonTable';
import {
  UploadFileMaxSize,
  filterFoodOptionColumnsForAttendee,
  formValueChangeHandler,
  formatCurrency,
  getLocalStorageUserInfo,
} from '../../../Util/commonUtility';

import CustomCollapse from '../../CommonComponents/CommonAccordian';

import SponsorPerksCard from '../../CommonComponents/SponsorPerksComponent';
import AddAttendeeeComponent from '../../CommonComponents/AttendeeAddComponent';
import AdminActionsDropdown from '../../CommonComponents/AdminActionsDropdown/AdminActionsDropdown';
import SponsorOptionPageHeroText from '../../CommonComponents/SponsorOptionPageHeroText';
import NavigationComponent from '../../CommonComponents/CommonBackButton';
import SponsorModal from '../../CommonComponents/SponsorModal';
import {ApplicationString} from '../../../Localization/Locales/en';

import SponsorDetailForm from './SponsorDetailForm';
import {applicationConfig} from '../../../Util/config';
import {useThrottle} from '../../../hooks/useThrottle';
import {useDispatch} from 'react-redux';
import CommonUserModal from '../../CommonComponents/CommonUserModal';
import {FETCH_ERROR} from './../../../Constant/ActionTypes';

const EventSponsorComponent = ({
  eventName,
  eventType,
  totalCartPrice,
  eventRegisterData,
  allSponsorAttendeesData,
  sponsorData,
  deleteAttendeeById,
  postAttendeesById,
  postSponsorAttendeesById,
  allEventOptionsFoodData,
  updateSponsorAttendee,
  SponsorAttendeeOptionById,
  sponsorPerksData,
  attendeesDataWithPerks,
  updateSponosor,
  fetchSponsorAttendeesList,
  setAttendeesData,
  setListAttendeesDataWithPerks,
}) => {
  const {id, optionId} = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [editingKey, setEditingKey] = useState('');
  const [form] = Form.useForm();
  const [isAddAttendee, setIsAddAttendee] = useState(false);
  const isEditing = (record) => record.id === editingKey;
  const [sponsorOption, setSelectOptionsValue] = useState([]);
  const [loading, setLoading] = useState(false);
  const [api] = notification.useNotification();
  const [eventSponsorshipOption, setEventSponsorshipOption] = useState();
  const [eventSponsorPerksData, setEventSponsorPerksData] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [eventSponsorData, setEventSponsor] = useState([]);
  const [validBannerFileList, setValidBannerFileList] = useState([]);
  const [validLogoFileList, setValidLogoFileList] = useState([]);
  const localUserData = getLocalStorageUserInfo();
  const [isSponsorModalOpen, setIsSponsorModalOpen] = useState(false);
  const [disableAttendeeClicks, setDisableAttendeeClicks] = useState(false);
  const [disablePaymentClicks, setDisablePaymentClicks] = useState(false);

  useEffect(() => {
    if (sponsorPerksData) {
      setEventSponsorPerksData(sponsorPerksData);
    } else {
      SponsorAttendeeOptionById(optionId);
    }
  }, [sponsorPerksData, optionId]);

  useEffect(() => {
    if (Object.keys(eventSponsorData).length > 0) {
      setIsModalOpen(false);
    }
  }, [sponsorData]);

  const edit = (record) => {
    const EditData = {
      ...record,
      attendee_email_confirm: record?.attendee_email
        ? record?.attendee_email
        : '',
      food_option:
        record?.food_option === ''
          ? []
          : record.event_registration_options?.food?.event_option_id ||
            'no-food',
      registration_option:
        record.event_registration_options?.registration?.event_option_id,
    };
    if (!isAddAttendee) {
      form.setFieldsValue({
        ...EditData,
      });
    }
    setEditingKey(record.id);
  };

  const cancel = () => {
    if (isAddAttendee) {
      allSponsorAttendeesData.pop();
      setIsAddAttendee(false);
      setAttendeesData([...allSponsorAttendeesData]);
    }
    form.resetFields();
    setIsAddAttendee(false);
    setEditingKey('');
  };

  const save = async (data) => {
    const row = await form.validateFields();
    const sponsorData = {
      ...row,
      event_option_perk_id: sponsorOption?.event_sponsorship_perk?.id,
      sponsorship_option_id:
        sponsorOption?.event_sponsorship_perk?.event_option_id,
      event_id: Number(id),
      food_option:
        row.food_option === 'no-food' || eventType?.toLowerCase() === 'webcast'
          ? {}
          : {
              type: 'food',
              event_option_id: row.food_option,
              qty: 1,
            },
      registration_option: {
        type: 'registration',
        event_option_id: row.registration_option,
        qty: 1,
      },
    };

    if (isAddAttendee) {
      postAttendeesById(sponsorData, setEditingKey, form, setIsAddAttendee);
    } else {
      const EditData = {
        ...row,
        event_option_perk_id:
          eventSponsorshipOption?.event_sponsorship_perk?.id,
        sponsorship_option_id: eventSponsorshipOption?.event_option_id,
        event_id: Number(id),
        food_option:
          row?.food_option === 'no-food' ||
          eventType?.toLowerCase() === 'webcast'
            ? {}
            : {
                type: 'food',
                event_option_id: row?.food_option,
                qty: 1,
                id: data?.food_option?.id,
              },
        registration_option: {
          type: 'registration',
          event_option_id: row?.registration_option,
          qty: 1,
          id: data?.registration_option?.id,
        },
      };
      updateSponsorAttendee(EditData, data.id, setEditingKey, form);
    }
  };

  const handleDelete = (id) => {
    deleteAttendeeById(id);
  };

  useEffect(() => {
    const newRow = {
      id: '1',
      attendee_first_name: '',
      attendee_last_name: '',
      attendee_company: '',
      attendee_email: '',
      event_id: '',
      is_reps: '',
      event_option_type: '',
      food_option: '',
      price: '',
    };
    if (allSponsorAttendeesData && allSponsorAttendeesData?.length === 0) {
      setIsAddAttendee(true);
      edit(newRow);
      setAttendeesData([...allSponsorAttendeesData, newRow]);
    }
  }, [allSponsorAttendeesData]);

  useEffect(() => {
    const newRow = {
      id: '1',
      attendee_first_name: '',
      attendee_last_name: '',
      attendee_company: '',
      attendee_email: '',
      event_id: '',
      is_reps: '',
      event_option_type: '',
      food_option: '',
      price: '',
    };
    if (allSponsorAttendeesData?.length === 0) {
      const loading =
        !allSponsorAttendeesData || allSponsorAttendeesData.length === 0;
      setLoading(loading);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
      setIsAddAttendee(true);
      edit(newRow);
      setAttendeesData([...allSponsorAttendeesData, newRow]);
    }
  }, [allSponsorAttendeesData]);
  const addRow = () => {
    const newRow = {
      id: '',
      attendee_first_name: '',
      attendee_last_name: '',
      attendee_company: '',
      attendee_email: '',
      event_id: '',
      is_reps: '',
      event_option_type: '',
      food_option: '',
      price: '',
    };
    const existingAttendee = allSponsorAttendeesData.find((obj) =>
      Object.keys(newRow).every((key) => obj[key] === newRow[key]),
    );
    if (existingAttendee || editingKey !== '') {
      return;
    } else {
      setIsAddAttendee(true);
      edit(newRow);
      setAttendeesData([...allSponsorAttendeesData, newRow]);
    }
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  useEffect(() => {
    const eventOptionData =
      sponsorData?.event_registration_options?.sponsorship;
    const label =
      eventOptionData?.name + '-' + formatCurrency(eventOptionData?.price);
    if (eventOptionData) {
      setEventSponsorshipOption(eventOptionData);
      setSelectOptionsValue(eventOptionData);
    }
    setEventSponsor(sponsorData);

    sponsorData?.banners?.length > 0 &&
      setValidBannerFileList((prev) => [
        {
          uid: sponsorData?.banners[0]?.id,
          name: sponsorData?.banners[0]?.name,
          status: 'done',
          url: `${applicationConfig.UPLOAD_URL}/${sponsorData?.banners[0]?.name}`,
        },
      ]);
    sponsorData?.logos?.length > 0 &&
      setValidLogoFileList((prev) => [
        {
          uid: sponsorData?.logos[0]?.id,
          name: sponsorData?.logos[0]?.name,
          status: 'done',
          url: `${applicationConfig.UPLOAD_URL}/${sponsorData?.logos[0]?.name}`,
        },
      ]);
  }, [sponsorData]);

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      editable: true,
      width: '15%',
      render: (text, record) => {
        return (
          <Tag prefixCls='regularAttendeesTag'>
            {record.attendee_first_name} {record.attendee_last_name}
          </Tag>
        );
      },
    },
    {
      title: 'Email',
      dataIndex: 'attendee_email',
      editable: true,
      width: '18%',
      render: (text) => {
        return <Tag prefixCls='regularAttendeesTag'>{text}</Tag>;
      },
    },
    {
      title: 'Option',
      dataIndex: 'option',
      key: 'registration_and_food_option',
      editable: true,
      width: '15%',
      render: (_, record) => {
        const registrationName =
          record?.event_registration_options?.registration?.name;
        const price = record?.event_registration_options?.registration?.price;
        const foodName =
          record?.event_registration_options?.food?.name || 'No Food';
        const foodPrice = record?.event_registration_options?.food?.price;
        return (
          <div className='option-tag-container'>
            <Tag prefixCls='regularAttendeesTag'>
              Registration: {registrationName} - {formatCurrency(price, 'USD')}
            </Tag>
            {eventType !== 'Webcast' && (
              <Tag prefixCls='regularAttendeesTag'>
                Food: {foodName}{' '}
                {foodPrice ? '- ' + formatCurrency(foodPrice, 'USD') : ''}
              </Tag>
            )}
          </div>
        );
      },
    },

    {
      title: 'Company/Affiliation',
      dataIndex: 'attendee_company',
      key: 'company',
      width: '15%',
      editable: true,
      render: (text) => {
        return <Tag prefixCls='regularAttendeesTag'>{text}</Tag>;
      },
    },
    {
      title: 'Price',
      dataIndex: 'total_cost',
      width: '5%',
      render: (text) => {
        return (
          <h4 className='regularAttendees-price'>{formatCurrency(text)}</h4>
        );
      },
    },
    {
      title: '  ',
      dataIndex: 'action',
      width: '6%',
      render: (_, record) => {
        return (
          <AdminActionsDropdown
            record={record}
            usedForAttendeeTable={true}
            editBool={true}
            attendeeEditingKey={editingKey}
            handleCancelClick={cancel}
            handleSaveClick={save}
            deleteBool={true}
            handleEditClick={edit}
            handleDeleteClick={() => handleDelete(record.id)}
            isAddAttendee={isAddAttendee}
          />
        );
      },
    },
  ];

  const handleConfirm = useThrottle(() => {
    if (Object.keys(sponsorData)?.length <= 0 || disablePaymentClicks) {
      dispatch({
        type: FETCH_ERROR,
        payload: 'Kindly take action and save the sponsor details first.',
      });
      // message.warning('Please add sponsor details first!');
      return;
    }
    setIsSponsorModalOpen(true);
  }, 2000);

  const onConfirm = () => {
    navigate(`/eventSponsorCart/${id}/${optionId}`);
  };

  const handleConfirmModalCancel = () => {
    setIsSponsorModalOpen(false);
  };

  const handleCancel = () => {
    navigate(`/eventDetails/${id}`);
  };

  const showModal = () => {
    if (Object.keys(sponsorData)?.length <= 0) {
      message.warning('Please add sponsor details first!');
      return;
    }
    setIsModalOpen(true);
  };

  const handleClose = () => {
    setIsModalOpen(false);
  };

  const uploadLogoFormats = ['image/png', 'image/jpeg', 'image/jpg'];
  const uploadBannerFormats = [
    'application/pdf',
    'image/png',
    'image/jpeg',
    'image/jpg',
  ];

  const beforeUploadLogos = {
    name: 'file',
    multiple: true,
    maxCount: 10,
    // listType: 'picture',
    beforeUpload: (file) => {
      const isRightFormat = uploadLogoFormats.includes(file.type);
      if (!isRightFormat) {
        dispatch({
          type: FETCH_ERROR,
          payload: 'You can only upload png, jpg, jpeg files',
        });
        return;
      }
      const isLt2M = file.size / 1024 / 1024 < UploadFileMaxSize;
      if (!isLt2M) {
        dispatch({
          type: FETCH_ERROR,
          payload: `File must smaller than ${UploadFileMaxSize}MB!`,
        });
        return false;
      }
      if (file?.type?.includes('image')) {
        return new Promise((resolve, reject) => {
          const img = new Image();
          img.src = URL.createObjectURL(file);
          img.onload = () => {
            if (img.width >= 200 && img.height >= 200) {
              setValidLogoFileList((prev) => [file]);
              resolve(isRightFormat && isLt2M);
            } else {
              dispatch({
                type: FETCH_ERROR,
                payload: 'Image must be minimum 200x200!',
              });
              setValidLogoFileList((prev) =>
                prev.filter((f) => f.uid !== file.uid),
              );
              reject();
            }
          };
          img.onerror = reject;
        });
      } else {
        setValidLogoFileList((prev) => [file]);
        return isRightFormat && isLt2M;
      }
    },
  };

  const beforeUploadBanner = {
    name: 'file2',
    multiple: true,
    maxCount: 10,
    // listType: 'picture',
    beforeUpload: (file) => {
      const isRightFormat = uploadBannerFormats.includes(file.type);
      if (!isRightFormat) {
        dispatch({
          type: FETCH_ERROR,
          payload: 'You can only upload pdf, png, jpg, jpeg files',
        });
        return;
      }
      const isLt2M = file.size / 1024 / 1024 < UploadFileMaxSize;
      if (!isLt2M) {
        dispatch({
          type: FETCH_ERROR,
          payload: `File must smaller than ${UploadFileMaxSize}MB!`,
        });
        return false;
      }
      if (file?.type?.includes('image')) {
        return new Promise((resolve, reject) => {
          const img = new Image();
          img.src = URL.createObjectURL(file);
          img.onload = () => {
            if (img.width >= 200 && img.height >= 200) {
              setValidBannerFileList((prev) => [file]);
              resolve(isRightFormat && isLt2M);
            } else {
              dispatch({
                type: FETCH_ERROR,
                payload: 'Image must be minimum 200x200!',
              });
              setValidBannerFileList((prev) =>
                prev.filter((f) => f.uid !== file.uid),
              );
              reject();
            }
          };
          img.onerror = reject;
        });
      } else {
        setValidBannerFileList((prev) => [file]);
        return isRightFormat && isLt2M;
      }
    },
  };

  const removeBannerFileHandler = (file) => {
    setValidBannerFileList((prevFileList) => {
      return prevFileList.filter((item) => item?.uid !== file?.uid);
    });
  };

  const removeLogoFileHandler = (file) => {
    setValidLogoFileList((prevFileList) => {
      return prevFileList.filter((item) => item?.uid !== file?.uid);
    });
  };

  const dummyRequest = ({file, onSuccess}) => {
    setTimeout(() => {
      onSuccess('ok');
    }, 0);
  };

  const onFinishSponsor = (
    values,
    setSponsorCreatedSuccessFully,
    setEditDetails,
  ) => {
    const eventOptionID =
      eventSponsorPerksData?.eventSponsorshipPerk?.event_option_id;
    const SponsorData =
      Object.keys(eventSponsorData).length > 0
        ? {
            ...values,
            event_id: id,
          }
        : {
            ...values,
            sponsorship_option_id: eventOptionID,
            event_id: id,
          };

    const formData = new FormData();
    Object.entries(SponsorData).forEach(([key, value]) => {
      if (key === 'logo') {
        if (sponsorData) {
          sponsorData?.logos?.length > 0
            ? sponsorData?.logos[0]?.id === validLogoFileList[0]?.uid
              ? formData.append('logo_ids', `[${sponsorData?.logos[0]?.id}]`)
              : formData.append(
                  'logos',
                  validLogoFileList[0]?.originFileObj ||
                    validLogoFileList[0] ||
                    [],
                )
            : formData.append(
                'logos',
                validLogoFileList[0]?.originFileObj ||
                  validLogoFileList[0] ||
                  [],
              );
        }
      } else if (key === 'flyer') {
        if (sponsorData) {
          sponsorData?.banners?.length > 0
            ? sponsorData?.banners[0]?.id === validBannerFileList[0]?.uid
              ? formData.append(
                  'banner_ids',
                  `[${sponsorData?.banners[0]?.id}]`,
                )
              : formData.append(
                  'banners',
                  validBannerFileList[0]?.originFileObj ||
                    validBannerFileList[0] ||
                    [],
                )
            : formData.append(
                'banners',
                validBannerFileList[0]?.originFileObj ||
                  validBannerFileList[0] ||
                  [],
              );
        }
      } else if (key === 'sponsorship_option') {
        const new_value = JSON.stringify(value);
        formData.append(key, value ? new_value : '');
      } else {
        formData.append(key, value ? value : '');
      }
    });
    if (Object.keys(eventSponsorData).length > 0) {
      updateSponosor(formData, values?.id, setEditDetails);
    } else {
      postSponsorAttendeesById(
        formData,
        setSponsorCreatedSuccessFully,
        setEditDetails,
      );
    }
    handleClose();
  };

  const mergedColumns = filterFoodOptionColumnsForAttendee(
    columns,
    eventType,
    isEditing,
  );

  return (
    <React.Fragment>
      <SponsorModal
        eventSponsorPerksData={eventSponsorPerksData}
        handleClose={handleClose}
        eventSponsorData={eventSponsorData}
        isModalOpen={isModalOpen}
        onFinish={onFinishSponsor}
        localUserData={localUserData}
        removeLogoFileHandler={removeLogoFileHandler}
        removeBannerFileHandler={removeBannerFileHandler}
        dummyRequest={dummyRequest}
        validLogoFileList={validLogoFileList}
        validBannerFileList={validBannerFileList}
        beforeUploadLogos={beforeUploadLogos}
        beforeUploadBanner={beforeUploadBanner}
        fetchSponsorAttendeesList={(id) => {
          fetchSponsorAttendeesList(id);
        }}
      />
      <CommonUserModal
        visible={isSponsorModalOpen}
        onCancel={handleConfirmModalCancel}
        onConfirm={onConfirm}
        confirmBtnText={'Agree & Continue'}
        content={
          <React.Fragment>
            <div className='payment-modal'>
              Attendee information must be finalized 14 days before the event.
              Any changes within 14 days of the event require administrator
              approval at <a href='mailto:info@pbss.org'>info@pbss.org</a>.
              Requests for adjustments must be submitted to the admin no later
              than 4 days before the event.
            </div>
          </React.Fragment>
        }
      />
      <Row gutter={[0, 30]} className='pbssMainContainerRow'>
        <div className='stickyEventName'>
          <SponsorOptionPageHeroText
            title={eventName}
            subTitle={ApplicationString['userEventSponsorComponent.subheading']}
            extra={
              <Button
                onClick={() => {
                  navigate(`/addAttendees/${id}`);
                }}
                className='reg-navigation-btn'>
                {
                  ApplicationString[
                    'userEventSponsorComponent.registerAs.regularAttendeeText'
                  ]
                }
              </Button>
            }
          />
        </div>
        <Col span={24}>
          <SectionLayout>
            <NavigationComponent
              name={
                ApplicationString[
                  'userEventSponsorComponent.backtoSponsorOption'
                ]
              }
              urlPath={`sponsorshipOption/${id}`}
            />
          </SectionLayout>
        </Col>
        <Col span={24}>
          <SectionLayout>
            <SponsorPerksCard
              hideButton
              multiple={false}
              hideUpload={eventSponsorPerksData?.sponsorship_type == '3'}
              handleClickUpload={showModal}
              handleCloseUpload={handleClose}
              upload
              title={eventSponsorPerksData?.name}
              sponsorshipType={eventSponsorPerksData?.sponsorship_type}
              spot={
                eventSponsorPerksData?.qty - eventSponsorPerksData?.used_qty >=
                0
                  ? eventSponsorPerksData?.qty - eventSponsorPerksData?.used_qty
                  : 0
              }
              totalSpot={
                eventSponsorPerksData?.qty >= 0 ? eventSponsorPerksData?.qty : 0
              }
              price={eventSponsorPerksData?.price}
              perks={eventSponsorPerksData?.eventSponsorshipPerk}
              id={eventSponsorPerksData?.id}
              eventId={eventSponsorPerksData?.event_id}
              optionId={optionId}
              attendeeDataOptionId={
                eventSponsorData?.event_registration_options?.sponsorship
                  ?.event_sponsorship_perk?.event_option_id
              }
            />
          </SectionLayout>
        </Col>
        <Col span={24}>
          <SectionLayout>
            <SponsorDetailForm
              alertInformation={
                eventSponsorPerksData?.eventSponsorshipPerk
                  ?.sponsor_contact_content
              }
              eventSponsorData={eventSponsorData}
              onFinish={onFinishSponsor}
              localUserData={localUserData}
              setDisableAttendeeClicks={setDisableAttendeeClicks}
            />
          </SectionLayout>
        </Col>
        <Col span={24}>
          <CommonTable
            alertInformation={
              eventSponsorPerksData?.eventSponsorshipPerk?.sponsor_perk_content
            }
            attendeesDataWithPerks={attendeesDataWithPerks}
            allEventOptionsFoodData={allEventOptionsFoodData}
            sponsorOption={sponsorOption}
            onSave={(
              Data,
              setEditingKey,
              form,
              setIsAddAttendee,
              setDisableAttendeeClicks,
              setDisablePaymentClicks,
              setDisableEditClicks,
            ) => {
              postAttendeesById(
                Data,
                setEditingKey,
                form,
                setIsAddAttendee,
                setDisableAttendeeClicks,
                setDisablePaymentClicks,
                setDisableEditClicks,
              );
            }}
            onEdit={(
              id,
              Data,
              setEditingKey,
              form,
              setDisableAttendeeClicks,
              setDisablePaymentClicks,
              setDisableEditClicks,
            ) => {
              updateSponsorAttendee(
                id,
                Data,
                setEditingKey,
                form,
                setDisableAttendeeClicks,
                setDisablePaymentClicks,
                setDisableEditClicks,
              );
            }}
            onDelete={handleDelete}
            sponsorPerksData={eventSponsorPerksData}
            eventType={eventType}
            setListAttendeesDataWithPerks={setListAttendeesDataWithPerks}
            setDisablePaymentClicks={setDisablePaymentClicks}
            disablePaymentClicks={disablePaymentClicks}
          />
        </Col>
        {/* <Col span={24}>
          <SectionLayout>
            <Form
              form={form}
              onValuesChange={(changedValues) =>
                formValueChangeHandler(form, changedValues)
              }>
              <Skeleton
                loading={loading}
                prefixCls='table-skeleton'
                active
                paragraph={{rows: allSponsorAttendeesData?.length}}>
                <div className='attendees-label'>
                  {sponsorPerksData?.sponsorship_type === 0
                    ? ApplicationString[
                        'userAddAttendeeComponent.regularAttendeeForVendorAttendee.label'
                      ]
                    : ApplicationString[
                        'userAddAttendeeComponent.regularAttendeeForSponsorAttendee.label'
                      ]}
                </div>
                <Table
                  pagination={false}
                  prefixCls='attendees-table-container'
                  showExpandColumn={false}
                  components={{
                    body: {
                      cell: (props) => (
                        <EditableCell
                          allEventOptionsRegistrationData={
                            allRegularRegistrationData
                          }
                          sponsorData={sponsorData}
                          eventType={eventType}
                          attendeesData={allSponsorAttendeesData}
                          {...props}
                        />
                      ),
                    },
                  }}
                  scroll={{x: columns?.length * 150}}
                  dataSource={allSponsorAttendeesData}
                  columns={mergedColumns}
                />
              </Skeleton>
            </Form>
          </SectionLayout>
        </Col>{' '} */}
        {/* <Col span={24}>
          <SectionLayout extendClass='addAttendeeWrapperEventSponsorComponent'>
            <AddAttendeeeComponent addRow={addRow} />
          </SectionLayout>
        </Col> */}
        <Col span={24}>
          <SectionLayout>
            <ConfirmationAction
              heading={
                ApplicationString[
                  'userAttendeeCartComponent.totalAmountToBePaid'
                ]
              }
              data={eventRegisterData?.attendees}
              submitName={
                ApplicationString['userEventSponsorComponent.ProceedToPay']
              }
              onConfirm={handleConfirm}
              onCancel={handleCancel}
              totalCartPrice={totalCartPrice}
              disable={disableAttendeeClicks}
            />
          </SectionLayout>
        </Col>
        <Col span={24}>
          <SectionLayout>
            <CustomCollapse />
          </SectionLayout>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default EventSponsorComponent;
